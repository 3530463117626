jQuery(function($) {

  function locationMenuToggle(e) {
    $(".location-menu-link, a[href$='/menu/'], a[href$='/menu']").click(function(e) {
      e.preventDefault();
      $(".fullscreen-overlay").addClass("active");
      $(".locmenu").removeClass("inactive");
      $(".locmenu").addClass("active");
      $("body").addClass("fixed");
    })

    $('.locmenu__toggle').click(function() {
      $(".fullscreen-overlay").removeClass("active");
      $(".locmenu").removeClass("active");
      $(".locmenu").addClass("inactive");

      if (!($(".main-navigation").hasClass("toggled"))) {
        $("body").removeClass("fixed");
      }
    });
  }

  function mobileNavAddClasses() {
    var mobileMenuToggle = $(".menu-toggle");
    var topBannerHeight = $(".top-banner").outerHeight();

    mobileMenuToggle.click(function() {
      $("body").toggleClass("fixed");
    });
  }

  function mobileNavSpacer() {
    // Get the dimensions of the viewport
    var width = window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
    var mobileSpacer = $(".mobilenav-spacer");
    var topBannerHeight = $(".top-banner").outerHeight();


    if(width < 768) {
      mobileSpacer.css("height", topBannerHeight);
    }
  }

  function smoothScrolling() {
    // Select all links with hashes
    $('a[href*="#"]:not([data-fancybox])')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function(event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
          &&
          location.hostname == this.hostname
        ) {
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000, function() {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) { // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              };
            });
          }
        }
      });
  }

  function scrollFilterClass() {
    var winScroll = $(window).scrollTop();
    var firstMenuGroupOnTop = $(".menu__group").first().offset().top;
    if(winScroll >= firstMenuGroupOnTop) {
        $(".menu__filter").addClass('fixed');
        $(".menu__group").each(function() {
          if($(this).position().top <= winScroll - 20) {
            $mId = $(this).attr("id");
            $(".mfilter a.active").removeClass("active");
            $(".mfilter a[data-scroll='" + $mId +"']").addClass("active");
          }
        })
    } else {
      $(".menu__filter").removeClass("fixed");
      $(".mfilter a.active").removeClass("active");
      $(".menu__filter__type:first .mfilter li:first a").addClass("active");
    }
  }

  function createMenuFilterMobile() {
    var initOption = '<option value="Select">Select</option>';
    var select1 = $("<select />").attr("id", "mf1");
    var select2 = $("<select />").attr("id", "mf2");
    select1.prepend(initOption);
    select2.prepend(initOption);

    $(".menu__filter h6").each(function() {
      var option1 = $("<option />")
                      .attr("value", $(this).text())
                      .html($(this).html());
      select1.append(option1);
    });

    $(".mfilter a").each(function() {
      var parentName = $(this).parents(".mfilter").prev().text();
      var option2 = $("<option />")
                      .attr({
                        "data-parent": parentName,
                        value: $(this).attr("href"),
                        "disabled": true,
                      })
                      .html($(this).html());
      select2.append(option2);
    });

    $(".menu__filter--mobile").prepend(select1);
    $(".menu__filter--mobile").append(select2);

    $("#mf1").on("change", function(e) {
      var dataParent = e.target.value;
      $("#mf2 option").prop("disabled", true);
      $("#mf2").find("option[data-parent='" + dataParent + "'").prop("disabled", false);
    });

    $("#mf2").on("change", function(e) {
      var optionVal = $(this).val();
      $(".menu__group").hide();
      $(".menu__group" + optionVal).show();
    });
  }

  function initScrollReveal() {
    ScrollReveal().reveal(".top-banner", {delay: 300});
    ScrollReveal().reveal(".site-header", {delay: 300});
    ScrollReveal().reveal(".ct-block", {delay: 300});
    ScrollReveal().reveal(".menu__filter", {delay: 300});
    // ScrollReveal().reveal(".menu__group", {delay: 300});
    ScrollReveal().reveal(".site-footer", {delay: 100});
  }

  function respond() {
    //Put all responsive functions here
    mobileNavSpacer();
  }

  $(document).ready(function() {
    respond();
    $(".menu__filter__type:first .mfilter li:first a").addClass("active");
    mobileNavAddClasses();
    // locationMenuToggle();
    smoothScrolling();
    // createMenuFilterMobile();
    initScrollReveal();
  });

  $(window).on('load', function() {

  });

  $(window).on('resize', function() {
    respond();
  });

  $(window).on('scroll', function() {
    scrollFilterClass();
  });

}(jQuery));