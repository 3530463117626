/**
 * Site Entry Point
 */

 // Fonts
import "./../node_modules/slick-carousel/slick/fonts/slick.eot";
import "./../node_modules/slick-carousel/slick/fonts/slick.svg";
import "./../node_modules/slick-carousel/slick/fonts/slick.ttf";
import "./../node_modules/slick-carousel/slick/fonts/slick.woff";

// Global Javascript
import "./js/navigation";
import "./js/skip-link-focus-fix";

// matchHeight
import "./../node_modules/jquery-match-height/dist/jquery.matchHeight-min.js";

// ScrollReveal
import ScrollReveal from "scrollreveal";
window.ScrollReveal = ScrollReveal;

// Fancybox JS
import { Fancybox } from "@fancyapps/ui";
window.Fancybox = Fancybox;

// Slick JS
import "./../node_modules/slick-carousel/slick/slick.min.js";

// Custom JS
import "./js/main";

// Global Styles
import "@fancyapps/ui/dist/fancybox.css";
import "./../node_modules/slick-carousel/slick/slick.scss";
import "./../node_modules/slick-carousel/slick/slick-theme.scss";
import "./scss/index.scss";